import { Box, CircularProgress } from '@mui/material'
import { FC } from 'react'

export const LoadingView: FC = () => {
  return (
    <>
      <Box>
        <Box className="w-12 h-12">
          <CircularProgress />
        </Box>
      </Box>
    </>
  )
}

import React, { ReactNode, useCallback, useEffect, useState } from 'react'

import { useAuth } from '../Hooks/useAuth.ts'
import { useFetch } from '../Hooks/useFetch.ts'
import { RIGHTS } from './rights.ts'

type AuthType = ReturnType<typeof useAuth>

type UserContextInterface = AuthType & { refreshJwtToken: (value?: string) => Promise<string>; rights: RIGHTS[] }

const UserContext = React.createContext<UserContextInterface>({
  rights: [],
  token: '',
  expiresSoon: () => false,
  logout: () => {},
  setJwtToken: () => {},
  refreshJwtToken: () => {
    console.error('DEFAULT refreshJwtToken')
    return new Promise(() => '')
  },
  isLoggedIn: () => false,
})

export const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const { token, setJwtToken, logout, expiresSoon, isLoggedIn } = useAuth()
  const { fetchAdmin } = useFetch(token)
  const [rights, setRights] = useState<RIGHTS[]>([])

  const refreshJwtToken = useCallback(
    async (key?: string) => {
      const response = await fetchAdmin('/admin/auth/token', 'get', {}, { apikey: key })

      const token = await response.text()
      setJwtToken(token)
      return token
    },
    [fetchAdmin, setJwtToken],
  )
  useEffect(() => {
    if (token) {
      fetchAdmin('/admin/auth/rights', 'get')
        .then(async response => {
          const rights = await response.json()
          if (rights.rights) {
            setRights(rights.rights)
          } else {
            setRights([])
          }
        })
        .catch(reason => {
          if (reason.message === 'Unauthorized') {
            logout()
          }
        })
    } else {
      setRights([])
    }
  }, [logout, fetchAdmin, token])
  return (
    <UserContext.Provider value={{ rights, token, setJwtToken, logout, expiresSoon, refreshJwtToken, isLoggedIn }}>
      {children}
    </UserContext.Provider>
  )
}
export default UserContext
